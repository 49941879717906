<template>
  <div class="sta-wrapper">
    <b-container fluid>
      <el-row :gutter="20">
        <el-col :span="24">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Select Indicators</h4>
            </template>
            <template v-slot:body>
              <span
                v-for="(value, key) in medicalChar"
                :key="key"
              >
                <span>
                  <b-button
                    pill
                    @click="click($event, key)"
                    @mouseenter="mouseEnter($event, key)"
                    @mouseleave="mouseLeave($event, key)"
                    :style="{
                      color: ch.get(key).midColor,
                      borderColor: ch.get(key).midColor
                    }"
                    variant="outline-primary"
                    class="mb-3 mr-1"
                    id="selector-button"
                  >
                    {{ value }}
                  </b-button>
                </span>
              </span>
            </template>
          </iq-card>
          <iq-card>
            <template v-slot:body>
              <div class="title">
                <h4 class="card-title">Indicators Analysis</h4>
                <el-switch
                  v-model="show_standard"
                  active-text="show standard"
                >
                </el-switch>
              </div>
              <statistics-chart2
                :data="data2"
                :min="min"
                :max="max"
                :show_standard="show_standard"
              />
            </template>
          </iq-card>
        </el-col>
        <el-col :span="12">
          <iq-card>
            <template v-slot:body>
              <div class="title">
                <h4 class="card-title">Indicators Analysis</h4>
              </div>
              <statistics-chart1 :data="data1" />
            </template>
          </iq-card>
        </el-col>
      </el-row>
    </b-container>
  </div>
</template>

<script>
import { medicalCharChineseShort, medicalStandard } from '@/util/info'
import statisticsChart1 from '@/views/Statistics/statisticsChart1'
import statisticsChart2 from '@/views/Statistics/statisticsChart2'
import axios from 'axios'
import ColorHelper from '../../util/ColorHelper'
import { xray } from '../../config/pluginInit'

export default {
  components: {
    statisticsChart1,
    statisticsChart2
  },

  data() {
    return {
      selected: '',
      data1: undefined,
      data2: undefined,
      last: null,
      min: 0,
      max: 0,
      show_standard: true,
      medicalCharChinese: medicalCharChineseShort,
      medicalChar: {
        cl: 'Chlorine',
        co2: 'CO2CP',
        hgb: 'HGB',
        ca: 'Calcium',
        k: 'Potassium',
        na: 'Sodium',
        alb: 'Albumin',
        crp: 'hs-CRP',
        glu: 'Glucose',
        dia: 'Diastolic\npressure'
      },
      medicalStandard: medicalStandard
    }
  },

  computed: {
    ch() {
      return this.$store.state.ch
    }
  },

  created() {
    if (this.$store.state.ch) {
      this.$store.commit('setCh', new ColorHelper())
    }
    if (
      parseInt(
        JSON.parse(window.localStorage.getItem('loginUser')).user_type
      ) === 2
    ) {
      this.medicalChar = { alb: 'Albumin' }
    }
  },

  mounted() {
    xray.index()
    this.$i18n.locale = 'en'
  },

  watch: {
    selected: {
      handler(curVal, oldVal) {
        if (curVal) {
          this.getData()
        }
      }
    }
  },

  methods: {
    getData() {
      let name = ''
      name = this.selected
      axios.get('../statistics_data2/' + name + '2.json').then(res => {
        this.data2 = res.data
      })
      axios.get('../statistics_data2/' + name + '1.json').then(res => {
        this.data1 = res.data
      })
      let standard = medicalStandard[name]
      if (standard === undefined) {
        this.min = 0
        this.max = 0
      } else {
        this.min = standard[0]
        this.max = standard[1]
      }
    },
    mouseEnter(e, value) {
      if (this.selected !== value) {
        e.target.style.color = '#ffffff'
        e.target.style.background = this.ch.get(value).midColor
      }
    },
    mouseLeave(e, value) {
      if (this.selected !== value) {
        e.target.style.color = this.ch.get(value).midColor
        e.target.style.background = '#ffffff'
      }
    },
    click(e, value) {
      if (this.selected !== value) {
        if (this.last) {
          this.last.style.color = this.last.style.background
          this.last.style.background = '#ffffff'
        }
        this.selected = value
        this.last = e.target
        // console.log(this.selected)
      } else {
        e.target.style.color = this.ch.get(value).midColor
        e.target.style.background = '#ffffff'
        this.selected = ''
        this.last = null
      }
    }
  }
}
</script>

<style scoped>
.el-switch {
  float: right;
  padding-top: 10px;
}
h4 {
  float: left;
}
.title {
  margin-bottom: 35px;
}
.sta-wrapper {
  margin-top: -80px;
}
.card-title {
  margin-bottom: 0 !important;
}
</style>
